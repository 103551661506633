<script lang="ts" setup>
import { useStore } from "@/entities";
import { ref, onBeforeUpdate } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";

const store = useStore();
const route = useRoute();
const router = useRouter();

const loader = ref(true);

async function getContent() {
  // @ts-ignore
  const res = await store.dispatch("content/GET_CONTENTS", { content: route.params.content, page: route.params.page });
  if (!res) return router.push({ name: "Home" });
  return (loader.value = false);
}

onBeforeUpdate(async () => {
  await getContent();
});
getContent();
</script>

<template>
  <div v-if="loader"></div>
  <div class="details" v-else>
    <nav class="details__nav">
      <ul>
        <li v-for="link in $store.state.content.item.page?.titles" :key="link.routeName" :class="{ hidden: !link.state }">
          <router-link
            v-if="link.state"
            :to="{ path: `/details/${$route.params.content}/${$route.params.page}/${link.routeName}` }"
            :active-class="'details-active'"
            >{{ link.name }}</router-link
          >
        </li>
      </ul>
    </nav>
    <div class="details__content">
      <router-view></router-view>
    </div>
    <button @click="$router.push({ name: 'Home' })">На главную</button>
  </div>
</template>

<style lang="scss" scoped>
.details {
  flex-basis: calc(80% - 5rem);
  margin: 1rem 1rem 1rem 0;
  padding: 2rem 2rem 0 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1rem;
  & button {
    position: absolute;
    top: 3rem;
    right: 4rem;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      width: 4rem;
      height: 2rem;
      top: 0;
      left: -4rem;
      transform: translate(0, -25%);
      background: url("@/shared/assets/icons/button.svg") no-repeat center center / contain;
    }
  }
  &__nav {
    & ul {
      display: flex;
      gap: 1rem;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0;
    }
    & li {
      list-style-type: none;
      flex-basis: 15%;
      & a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        font-size: 1.4rem;
        text-decoration: none;
        color: #000;
        border-radius: 0.8rem;
      }
    }
  }
  &__content {
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  &-active {
    background-color: #efefef;
  }
}

.hidden {
  display: none;
}
</style>
