import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import { modules } from "./modules";

export const key: InjectionKey<Store<iStore.Store>> = Symbol();

export const store = createStore<iStore.Store>({
  modules,
});

export function useStore() {
  return baseUseStore(key);
}
