<script lang="ts" setup>
import { MainLayout, DetailLayout } from "@/layouts";
import { DefineComponent, computed } from "vue";
import { ScreenSaver } from "@/widgets/screensaver";
import { useRoute } from "vue-router";
import { contents } from "@/entities/store/modules/contents";
import { useStore } from "@/entities";

const store = useStore();
const layout: { [key: string]: DefineComponent<{}, {}, any> } = {
  main: MainLayout,
  detail: DetailLayout,
};

// for (let i = 0; i < contents.length; i++) {
//   for (let f = 0; f < contents[i].content.length; f++) {
//     for (let j = 0; j < contents[i].content[f].photo.length; j++) {
//       const image = new Image();
//       image.src = require("@/shared/assets/images/traditional/budism/asset1.webp");
//       store.commit("content/DOWNLOAD_IMAGE", {
//         name: contents[i].name,
//         path: contents[i].content[f].name,
//         imageName: contents[i].content[f].photo[j].url,
//         image,
//       });
//       console.log(store.state.content.images);
//     }
//   }
// }
const route = useRoute();
const resolveLayout = computed(() => route.meta.layout || "main");
</script>

<template>
  <ScreenSaver />
  <component :is="layout[resolveLayout]"></component>
</template>

<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  height: 100%;
  position: relative;
  background-color: #efefef;
}
</style>
