import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Details from "@/pages/Details.vue";
import Home from "@/pages/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "main" },
    component: Home,
  },
  {
    path: "/details/:content/:page/",
    name: "Details",
    meta: { layout: "detail" },
    beforeEnter: (to, from, next) => {
      const content = ["nomads", "xx", "nature", "traditional", "feature", "the_heritage", "incognito"].includes(
        to.params.content as string
      );
      if (!content) return next({ name: "Home" });
      next();
    },
    redirect: { name: "Info" },
    children: [
      {
        path: "info",
        name: "Info",
        component: () => import(/* webpackChunkName: "DetailsInfo" */ "@/pages/subPages/details/Info.vue"),
      },
      {
        path: "video",
        name: "Video",
        component: () => import(/* webpackChunkName: "DetailsVideo" */ "@/pages/subPages/details/Video.vue"),
      },
      {
        path: "photo",
        name: "Photo",
        component: () => import(/* webpackChunkName: "DetailsPhoto" */ "@/pages/subPages/details/Photo.vue"),
      },
    ],
    component: Details,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
