import { Module } from "vuex";
import { contents } from "./contents";

export const contentStore: Module<iStore.Contnet, iStore.Store> = {
  namespaced: true,
  state: {
    images: {},
    item: {
      exactPath: "",
      links: [],
      name: "",
      page: {
        name: "",
        info: [],
        photo: [],
        video: [],
        titles: [],
      },
      content: [],
    },
  },
  mutations: {
    SET_CONTENT(state, content) {
      state.item = content;
    },
    SET_PAGE(state, page) {
      state.item.page = page;
    },
    SET_EMPTY(state) {
      state.item.page.info = [];
    },
    DOWNLOAD_IMAGE(state, payload: { name: string; path: string; imageName: string; image: ImageBitmap }) {
      state.images[payload.name] = {};
      state.images[payload.name][payload.path] = {};
      state.images[payload.name][payload.path][payload.imageName] = payload.image;
    },
  },
  actions: {
    GET_CONTENTS({ commit, dispatch }, { content, page }): Promise<string | false> {
      return new Promise((resolve) => {
        const exist = contents.find((item) => item.name === content);
        if (!exist) return resolve(false);
        commit("SET_CONTENT", exist);
        const existPage = page === null ? exist.exactPath : page;
        dispatch("SET_PAGE", existPage).then((res) => {
          if (!res) return resolve(false);
          resolve(`${existPage}${res}`);
        });
      });
    },
    SET_PAGE({ commit, state }, payload): Promise<string | boolean> {
      return new Promise((resolve) => {
        const page = state.item.content.find((page) => page.name === payload);
        if (!page) return resolve(false);
        const links = [
          {
            routeName: "info",
            state: page.info.length > 0,
            name: "Информация",
          },
          {
            routeName: "photo",
            state: page.photo.length > 0,
            name: "Фотогалерея",
          },
          {
            routeName: "video",
            state: page.video.length > 0,
            name: "Видеораздел",
          },
        ];
        page.titles = links;
        commit("SET_PAGE", page);
        resolve(links[0].state ? "/info" : "/photo");
      });
    },
  },
};
