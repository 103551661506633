<script lang="ts" setup>
import { PropType, ref } from "vue";
import { useStore } from "@/entities";
import { useRouter } from "vue-router";

const _content = ref<HTMLElement>();

const props = defineProps({
  class: {
    type: String as PropType<string>,
    required: true,
  },
  icon: {
    type: String as PropType<string>,
    required: true,
  },
  title: {
    type: String as PropType<string>,
    required: true,
  },
  image: {
    type: String as PropType<string>,
    required: true,
  },
  direction: {
    type: String as PropType<string>,
    required: true,
  },
});
const top = ref(/bottomPosition|centerArrow/.test(props.class));
const store = useStore();
const router = useRouter();

function chengeContent({ title, image }: { title: string; image: string }) {
  store.commit("main/SET_MAIN_IMAGE", { title, image });
}

async function goToDetails(value: string) {
  const content = value.split(".")[0];
  const page = await store.dispatch("content/GET_CONTENTS", { content, page: null });
  if (!page) return router.push({ name: "Home" });
  return router.push({ path: `/details/${content}/${page}` });
}
</script>

<template>
  <div class="content" :class="class" @click="chengeContent({ title, image })" ref="_content">
    <div v-if="$store.state.main.mainImage === image" class="preview" @touchend="goToDetails(image)" @click="goToDetails(image)">
      <div class="preview-wrp">
        <img :src="require(`@/shared/assets/images/home/${$store.state.main.mainImage}`)" alt="image" />
        <h2>{{ $store.state.main.mainTitle }}</h2>
      </div>
    </div>
    <div v-else>
      <div
        class="content-icon"
        :style="{
          backgroundImage: `url(${require('@/shared/assets/icons/' + icon + '.svg')})`,
        }"
      ></div>
      <div v-if="top" class="content-title">
        <img :src="require(`@/shared/assets/icons/arrow${direction}.svg`)" alt="icon" />
        <p>{{ title }}</p>
      </div>
      <div v-else class="content-title">
        <p>{{ title }}</p>
        <img :src="require(`@/shared/assets/icons/arrow${direction}.svg`)" alt="icon" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
  align-self: center;
  width: 5rem;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("@/shared/assets/icons/bgcontent.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 50%;
  &-icon {
    width: 4rem;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: 95% 95%;
    background-position: center center;
  }
  &-title {
    width: 15rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & p {
      margin: 0;
      padding: 0;
      font-family: "Montserrat-SemiBold";
      color: #545456;
      text-align: center;
      font-size: 1.7rem;
    }
    & img {
      position: absolute;
      width: 3rem;
      aspect-ratio: 2/1;
    }
  }
}

.top .leftArrow,
.top .rightArrow {
  bottom: -1.9rem;
  & div > .content-title {
    bottom: 3rem;
    & img {
      bottom: -1.5rem;
    }
  }
}
.top .leftArrow {
  & img {
    right: 0;
    animation: topRight 2s ease-in infinite !important;
  }
}
.top .rightArrow {
  & img {
    left: 0;
    animation: topLeft 2s ease-in infinite !important;
  }
}

.bottom .rightArrow,
.bottom .leftArrow {
  top: -2rem;
  & div > .content-title {
    top: 3rem;
    & img {
      top: -1.5rem;
    }
  }
}

.bottom .rightArrow {
  & img {
    left: 0;
    animation: topLeft 2s ease-in infinite !important;
  }
}
.bottom .leftArrow {
  & img {
    right: 0;
    animation: topRight 2s ease-in infinite !important;
  }
}

.middlePosition.leftArrow {
  left: -32.9rem !important;
  & div > .content-title {
    bottom: 3rem;
    & img {
      bottom: -1.5rem;
      right: 0;
      animation: topRight 2s ease-in infinite !important;
    }
  }
}

.middlePosition.rightArrow {
  right: -32.9rem !important;
  & div > .content-title {
    bottom: 3rem;
    & img {
      bottom: -1.5rem;
      left: 0;
      animation: topLeft 2s ease-in infinite !important;
    }
  }
}

.centerArrow {
  & div > .content-title {
    left: -10.5rem;
    bottom: -2.5rem;
    & img {
      top: -1.5rem;
      left: 50%;
      transform: translate(-50%);
      animation: centerArrow 2s ease-in infinite;
    }
  }
}

.leftArrow {
  left: -13rem;
}

.leftArrow > div > .content-title {
  left: -16rem;
  & img {
    align-self: flex-end;
    margin-right: 1.5rem;
  }
}
.rightArrow {
  right: -12.9rem;
}

.rightArrow > div > .content-title {
  right: -16.5rem;
  & img {
    align-self: flex-start;
    margin-left: 0.5rem;
  }
}
.preview {
  position: relative;
  &-wrp {
    position: absolute !important;
    width: 15rem;
    padding: 0.7rem 0;
    background: center / cover no-repeat url("@/shared/assets/icons/bgcontent.svg");
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.7rem;
    gap: 0.5rem;
    & img {
      width: 90%;
      border-radius: 0.7rem;
      object-fit: cover;
    }
    & h2 {
      color: #fff;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }
}

@keyframes centerArrow {
  0% {
    left: 50%;
  }
  15% {
    left: 53%;
  }
  30% {
    left: 50%;
  }
  100% {
    left: 50%;
  }
}

@keyframes topLeft {
  0% {
    left: 0;
  }
  15% {
    left: -0.5rem;
  }
  30% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes topRight {
  0% {
    right: 0;
  }
  15% {
    right: -0.5rem;
  }
  30% {
    right: 0;
  }
  100% {
    right: 0;
  }
}
</style>
