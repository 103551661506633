<script lang="ts" setup>
import { Header } from "@/widgets/Header";
import { onMounted, ref } from "vue";

const loader = ref(true);

onMounted(() => {
  loader.value = false;
});
</script>

<template>
  <div v-if="loader"></div>
  <div class="container" v-else>
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  gap: 1rem;
}
</style>
