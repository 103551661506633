import { Module } from "vuex";

export const mainStore: Module<iStore.Main, iStore.Store> = {
  namespaced: true,
  state: {
    mainTitle: "",
    mainImage: "",
  },
  mutations: {
    SET_MAIN_IMAGE(state, content) {
      state.mainTitle = content.title;
      state.mainImage = content.image;
    },
  },
};
