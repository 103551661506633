<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const state = ref(false);
const router = useRouter();

function timer() {
  return setTimeout(() => {
    state.value = true;
  }, 1000 * 60 * 5);
}

const nextImages = ref({
  scythian: {
    current: 0,
    count: 20,
    name: "scythian",
  },
  prezident: {
    current: 0,
    count: 30,
    name: "prezident",
  },
  other: {
    current: 0,
    count: 9,
    name: "other",
  },
});

const url = ref("/prezident/asset1.webp");

const timeout = ref(timer());
const interval = ref<ReturnType<typeof setInterval> | null>(null);

document.addEventListener("visibilitychange", function () {
  if (document.hidden) {
    if (interval.value) clearInterval(interval.value);
    if (timeout.value) clearTimeout(timeout.value);
    state.value = false;
    return;
  } else {
    timeout.value = timer();
  }
});

watch(state, (val) => {
  if (val) {
    interval.value = setInterval(() => {
      const range = ["prezident", "prezident", "scythian", "scythian", "other"];
      const ind = Math.floor(Math.random() * range.length);
      const image = nextImages.value[range[ind] as "prezident" | "scythian" | "other"];
      image.current = (image.current + 1) % image.count;
      url.value = `/${image.name}/asset${image.current === 0 ? 1 : image.current}.webp`;
    }, 4000);
    router.push({ name: "Home" });
  } else {
    if (interval.value) clearInterval(interval.value);
  }
});

window.addEventListener("click", () => {
  clearTimeout(timeout.value);
  if (state.value) state.value = false;
  timeout.value = timer();
});
</script>

<template>
  <div class="screensaver" v-if="state" :class="{ show: state }">
    <img :src="require(`@/shared/assets/images/screensaver${url}`)" alt="image" />
  </div>
</template>

<style lang="scss" scoped>
.screensaver {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  overflow: hidden;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 6;
  & h1 {
    color: #fff;
  }
  & img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: scale-down;
  }
}
.show {
  display: flex !important;
  opacity: 0;
  animation: showscreen 0.3s ease-out forwards;
  & img {
    opacity: 0;
    animation: imageSlide 4s ease-out infinite;
  }
}
@keyframes showscreen {
  100% {
    opacity: 1;
  }
}
@keyframes imageSlide {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  97% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
