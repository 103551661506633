<script lang="ts" setup>
import { useStore } from "@/entities";
import { Titles } from "@/widgets/Titles";

const store = useStore();

function animateArrows(el: MouseEvent) {
  const div = el.target as HTMLDivElement;
  if (div.classList.value.split(" ").includes("home__content")) {
    store.state.main.mainImage = "";
  }
}
</script>

<template>
  <div class="home">
    <div class="home__link">
      <router-link aria-label :to="{ name: 'Home' }"></router-link>
      <div>
        <p>Портал для ознакомления</p>
        <div>
          <p>Выберите раздел</p>
          <img :src="require(`@/shared/assets/icons/arrowLeft.svg`)" alt="icon" />
        </div>
      </div>
    </div>
    <div class="home-wrp" @click="animateArrows">
      <div class="home__content top">
        <Titles
          :class="'topPosition leftArrow'"
          :icon="'icon5'"
          :title="'Тыва - земля кочевников'"
          :image="`nomads.webp`"
          :direction="'Left'"
        />
        <Titles
          :class="'topPosition rightArrow'"
          :icon="'icon1'"
          :title="'Тыва - в ХХ веке'"
          :image="`xx.webp`"
          :direction="'Right'"
        />
      </div>
      <div class="home__content middle">
        <Titles
          :class="'middlePosition leftArrow'"
          :icon="'icon6'"
          :title="'Тыва - край первозданной природы'"
          :image="`nature.webp`"
          :direction="'Left'"
        />
        <Titles
          :class="'middlePosition centerArrow'"
          :icon="'icon4'"
          :title="'Тыва традиционная'"
          :image="`traditional.webp`"
          :direction="'Left'"
        />
        <Titles
          :class="'middlePosition rightArrow'"
          :icon="'icon4'"
          :title="'Будущее Тувы'"
          :image="`feature.webp`"
          :direction="'Right'"
        />
      </div>
      <div class="home__content bottom">
        <Titles
          :class="'bottomPosition leftArrow'"
          :icon="'icon3'"
          :title="'Достояние Тувы'"
          :image="`the_heritage.webp`"
          :direction="'Left'"
        />
        <Titles
          :class="'bottomPosition rightArrow'"
          :icon="'icon2'"
          :title="'Тыва инкогнито'"
          :image="`incognito.webp`"
          :direction="'Right'"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home {
  position: relative;
  width: 95%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("@/shared/assets/icons/bgmain.svg");
  background-repeat: no-repeat;
  background-size: 90% 90%;
  background-position: center center;
  &-wrp {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: flex;
    justify-content: center;
  }
  &__link {
    width: 20%;
    position: absolute;
    top: 3rem;
    left: 0.5rem;
    & a {
      margin-bottom: 4rem;
      width: 100%;
      aspect-ratio: 3/1;
      display: block;
      background-image: url("@/shared/assets/icons/logo.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      cursor: pointer;
    }
    & div {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: center;
      & p {
        text-align: center;
        margin: 0;
        font-size: 1.6rem;
        font-family: "Montserrat-SemiBold";
        color: #545456;
      }
      & div {
        display: flex;
        flex-direction: row;
      }
      & img {
        width: 3rem;
        aspect-ratio: 2/1;
      }
    }
  }
}

.top {
  flex-basis: 20%;
}
.middle {
  flex-grow: 1;
}
.bottom {
  flex-basis: 20%;
}
</style>
