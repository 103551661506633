<script lang="ts" setup>
import { useStore } from "@/entities";
import { computed } from "vue";

const store = useStore();
const links = computed(() => store.state.content.item.links);
</script>

<template>
  <div class="header">
    <div class="header-wrp">
      <router-link class="header__link" aria-label :to="{ name: 'Home' }"></router-link>
      <header class="header__nav">
        <nav>
          <ul>
            <li v-for="(link, index) in links" :key="index" class="header__nav-link">
              <router-link
                :to="{ path: `/details/${$route.params.content}/${link.page}` }"
                active-class="header-active"
                exact-active-class="header-active-exact"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.52 40.89">
                  <g>
                    <path
                      style="fill-rule: evenodd"
                      d="M35.43,14.68l4.68,4.59L50.19,9.54,61.1,20.44,50.19,31.35,33.53,14.84C28.36,9.72,23.21,3.74,15.61,4.07A16,16,0,0,0,0,20.4,16.52,16.52,0,0,0,15.55,37a15.5,15.5,0,0,0,11.73-4.88l6-6-4.64-4.75L24.08,26c-2.46,2.48-4.51,4.33-8.51,4.21-5.87-.19-8.89-5-8.89-9.79,0-5.05,4.08-9.5,9-9.62,5.21-.12,9,4.45,14,9.65,6.63,6.92,13.74,13.47,20.44,20.45L70.52,20.44,50.07,0Z"
                    />
                  </g>
                </svg>
                <p>{{ link.name }}</p>
              </router-link>
            </li>
          </ul>
        </nav>
      </header>
      <img src="@/shared/assets/icons/icon1.svg" alt="icon" class="header-icon" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  margin: 1rem;
  flex-basis: 20%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    215deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 2%,
    rgba(170, 225, 255, 1) 27%,
    rgba(83, 196, 255, 1) 59%,
    rgba(211, 114, 229, 1) 86%,
    rgb(216, 57, 184) 100%
  );
  border-radius: 1rem;
  box-sizing: border-box;
  &-wrp {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  &__link {
    margin: 1rem auto 3rem auto;
    padding: 1rem 1.5rem;
    display: block;
    width: calc(100% - 4rem);
    aspect-ratio: 3/1;
    background-image: url("@/shared/assets/icons/mainlogo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    cursor: pointer;
  }
  &__nav {
    width: 90%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    & nav {
      width: 100%;
    }
    & ul {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0;
      & li {
        list-style-type: none;
        & a {
          position: relative;
          text-decoration: none;
          & p {
            margin: 0;
            padding: 0;
            font-size: 1.4rem;
            color: #fff;
          }
          & svg {
            position: absolute;
            width: 2rem;
            right: -2rem;
            top: 50%;
            fill: #fff;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
  &-active {
    display: block;
    position: relative;
    background-color: #fff !important;
    z-index: 1;
    & p {
      color: #000 !important;
      position: relative;
    }
    & svg {
      margin: 0;
      fill: #000 !important;
    }
    &::before {
      padding: 0.5rem 1rem;
      content: "";
      position: absolute;
      bottom: -0.5rem;
      left: -1.5rem;
      height: 100%;
      width: calc(100% + 10rem);
      background-color: #fff;
      z-index: -1;
      border-radius: 0.8rem;
    }
  }

  &-icon {
    display: block;
    width: 2.5rem;
    aspect-ratio: 1/3;
    justify-self: flex-start;
  }
}
</style>
