<script lang="ts" setup>
import { onMounted, ref } from "vue";

function resize() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const ratio = width / height;
  const html = document.getElementsByTagName("html");
  html[0].style.height = `${width * 0.5625}px`;
  //   html[0].style.fontSize = `${size}vw`;
}

onMounted(() => {
  window.addEventListener("resize", () => resize());
});
resize();
</script>

<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.container {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
